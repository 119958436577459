// import { useState, useEffect } from 'react';

// function useDebounce(value, delay) {
//   const [debouncedValue, setDebouncedValue] = useState(value);

//   useEffect(() => {
//     const timeoutRef = setTimeout(() => {
//       setDebouncedValue(value);
//     }, delay);

//     return () => clearTimeout(timeoutRef);
//   })

//   return debouncedValue;
// }

// export default useDebounce;


import { useRef } from 'react';

export function useDebounce(callback, delay) {
  const timeoutRef = useRef(null);

  const debouncedFunction = (...args) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debouncedFunction;
}

