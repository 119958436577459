import {useEffect, useState} from 'react';
import { useGetReportGoalsForVisitQuery, useGetMostRecentReportQuery } from '../redux/apiSlice';
// import useDeepCompareEffect from 'use-deep-compare-effect';

const useSetVisitGoals = ({clientId, hasGoals, searchStart, searchEnd}) => {
  // console.log("hook props: ", clientId, hasGoals, searchStart, searchEnd)

    const [skipMainQuery, setSkipMainQuery] = useState(true);
    const [skipFallbackQuery, setSkipFallbackQuery] = useState(true);
    const [visitGoals, setVisitGoals] = useState([])

    // Try find report matching the start and end
  const { data: mainReport } = useGetReportGoalsForVisitQuery(
    { clientId, searchStart, searchEnd },
    { skip: skipMainQuery }
  );

  // Try find last report available as fallback
  const { data: fallbackReport } = useGetMostRecentReportQuery(
    { clientId },
    { skip: skipFallbackQuery }
  );

  // console.log("main: ", mainReport, "fallback: ", fallbackReport)

  // if (isLoading) {
  //   console.log("fallback loading", isLoading)
  // } else if (isError) {
  //   console.log("fallback error: ", error)
  // } else if (isSuccess) {
  //   console.log("success: ", fallbackReport)
  // }

  useEffect(() => {
    if (clientId) {
      setSkipMainQuery(false);
    }
  }, [clientId]);

  // Trigger the fallback query if the main query returns no data
  useEffect(() => {
    const isMainReportEmpty = !mainReport || Object.keys(mainReport).length === 0 || !mainReport.goals?.length;

    if (clientId && isMainReportEmpty) {
      setSkipFallbackQuery(false);
    } else {
      setSkipFallbackQuery(true);
    }
  }, [clientId, mainReport]);

  
  useEffect(() => {
    const plan = (mainReport && mainReport.goals ? mainReport : fallbackReport);

    // console.log("plan: ", plan)

    if (!plan) {
      // No plan object exists
      setVisitGoals([{ title: 'No plan available', marked: false, note: '' }]);
      return;
    }

    if (!plan.goals || plan.goals.length === 0) {
      // Plan exists but has no goals
      setVisitGoals([{ title: 'Plan has no goals', marked: false, note: '' }]);
      return;
    }

    const planGoals = plan.goals?.map((goal) => ({
      title: goal.title,
      marked: false,
      note: '',
    }));

    if (hasGoals?.length >= 1) {
      const result = planGoals?.map((i) => {
        const new_info = hasGoals.find((g) => g.title === i.title);
        return {
          ...i,
          ...new_info,
        };
      });

      setVisitGoals(result);
    } else {
      setVisitGoals(planGoals);
    }
  }, [mainReport, fallbackReport, hasGoals]);
  

// console.log("visitGoals: ", visitGoals)
  return visitGoals
}

export default useSetVisitGoals