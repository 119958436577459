import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Grid, Typography, Divider, Stack } from '@mui/material';

import { 
		useGetBracketsOfUserByDateQuery,
		useGetUserQuery,
		useUserTimecardSummaryMutation,
		} from '../../redux/apiSlice';
import PropTypes from 'prop-types';
import TimeBracket from './TimeBracket';
import AddTimeBracket from './AddTimeBracket';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TimeChart from './TimeChart';
import SummaryContainer from './SummaryContainer';
import './Timecard.css';
import { useAuth } from '../../utilities/useAuth';
import { useSelector } from 'react-redux';
import TimeBracketStdDdct from './TimeBracketStdDdct';
import AdminUserLookup from './AdminUserLookup';
import SupUserLookup from './SupUserLookup';


function getTwoWeekPeriod() {
	const today = new Date();
  
	const year = today.getFullYear();
	const month = today.getMonth(); // 0-based
  
	let start, end;
  
	if (today.getDate() <= 15) {
		start = new Date(year, month, 1);
		end = new Date(year, month, 15);
	} else {
		start = new Date(year, month, 16);
		end = new Date(year, month + 1, 0); // Last day of the current month
	}
  
	const startISO = start.toISOString();
	const endISO = end.toISOString();
  
	return [startISO, endISO];
  }


export default function Timecard() {
	const { admin, supAdmin, status, id } = useAuth();
	// const userId = id;

	const [ userId, setUserId ] = useState(id);
	// console.log("timecard userId: ", userId)

	const { data: user } = useGetUserQuery(userId);
	const userStdDdct = user?.stdDdct;

	const useStd = useSelector(state => state.settings?.useStdDdct)

	let adminControl 
	let supControl

	if (admin || supAdmin) {
		adminControl = <AdminUserLookup userId={userId} setUserId={setUserId} />
	}
	if (status === "Supervisor") {
		let superId = id
		supControl = <SupUserLookup superId={superId} userId={userId} setUserId={setUserId} />
	}
	

	const defaultRange = getTwoWeekPeriod();

	const [skip, setSkip] = useState(true)

	const [value, setValue] = useState(!skip ? [] : defaultRange);
	const [totals, setTotals] = useState()

	let startDate = new Date(new Date(value[0]).setHours(0,0,0));
	let endDate = new Date(new Date(value[1]).setHours(23,59,59));
	const { data: brackets, 
			isLoading: isBracketsLoading, 
			isSuccess: isBracketsSuccess
	} = useGetBracketsOfUserByDateQuery({userId, startDate, endDate}, {skip});

	
    let isStart = startDate //new Date(new Date(startDate).setHours(0, 0, 0))
    let isEnd = endDate //new Date(new Date(endDate).setHours(23, 59, 59))
	const [pullTimeChart, {data: timeChartResult}] = useUserTimecardSummaryMutation();

	let timecardChart

    if (timeChartResult) {
    //    console.log("timechart result: ", timeChartResult)
       timecardChart= 
        <div>
				<Box sx={{alignItems: 'flex-start'}}>
					<Stack direction={'row'} spacing={2}>
						<SummaryContainer dateRange={value} data={timeChartResult} userId={userId} stdDdct={userStdDdct} totals={totals}/>
						{totals && <Typography>Current Totals: Entries- {totals.entryTotals}, Visits- {totals.visitTotals}, Miles- {totals.mileTotals}</Typography>}
					</Stack>				
				<Divider sx={{m:1,p:1}} />
				<TimeChart timeChartResult={timeChartResult} isStart={isStart} isEnd={isEnd} />
				</Box>
			<br/>
        </div>
    }

	function getTotals(items, prop) { return items.reduce(function(a, b) { if (!isNaN(b[prop])) { return parseFloat((a + b[prop]).toFixed(2)); } else { return a } }, 0); }

	useEffect(() => {
		if (timeChartResult) {
 
			const totals = {
				visitTotals : getTotals(timeChartResult, 'visitHours'),
				entryTotals : getTotals(timeChartResult, 'entryHours'),
				mileTotals : getTotals(timeChartResult, 'miles')
			}

			setTotals(totals)
		}
	}, [timeChartResult, setTotals])

	


	let bracketInfo = <div><h1>Select dates above and click run to view time card info</h1></div>
	
	if (isBracketsLoading) {
		// console.log("brackets loading")
		bracketInfo = <div><h3>Loading...</h3></div>
	} else if (isBracketsSuccess) {
		// console.log("brackets: ", brackets)
		if (useStd) {
			bracketInfo = 
		<div>
			
			<Grid container spacing={1} direction='column' sx={{ maxWidth: '500px' }}>
			
			{brackets &&
				brackets?.map((bracket) => {
					return (
					<>
					<Grid item xs={12}>
					<TimeBracketStdDdct 
						key={bracket._id} 
						bracket={bracket} 
						user={user} 
						hasStd={useStd}
					/>
					</Grid>
					</>
				)})
			}
			</Grid>
		</div>
		}
	else {
		bracketInfo = 
		<div>
			
			<Grid container spacing={3} direction='column' sx={{ maxWidth: '500px'}}>
			{brackets &&
				brackets?.map((bracket) => {
					return (
					<>
					<Grid item xs={12}>
					<TimeBracket 
						key={bracket._id} 
						bracket={bracket} 
						user={user} 
					/>
					</Grid>
					</>
				)})
			}
			</Grid>
		</div>
	}
	}


	return (
	<div>
	<span className='timecard-heading'><Typography>Timecard</Typography> </span>
	<br></br><br></br>
	{adminControl}
	{supControl}
	<LocalizationProvider dateAdapter={AdapterDateFns}>
		<fieldset width='300px'>
			<legend>Select Date Range</legend>
		<DateRangePicker
			value={value}
			onChange={(newValue) => {
				new Date().toISOString(newValue);
				setValue(newValue);				
			}}
			renderInput={(startProps, endProps) => (
				<React.Fragment>
					<TextField {...startProps} />
					<Box sx={{ mx: 2}}> to </Box>
					<TextField {...endProps} />
				</React.Fragment>
			)}
		/>
		<Button onClick={() => [setSkip(false), pullTimeChart({userId, isStart, isEnd, useStd})]} color='primary' variant='contained'>
			Run 
		</Button>
		</fieldset>
	</LocalizationProvider>
	<br></br><br></br><br></br>
	
		<>
		{timecardChart}
		</> 
		<Divider sx={{m:2, p:2}} /> 
		<AddTimeBracket user={user} /> <br />
		{/* <Box sx={{ maxWidth: '800px'}}> */}
		{bracketInfo}
		
		{/* </Box> */}
		
	
	</div>
	)
}

Timecard.propTypes = {
	user: PropTypes.any 
}