import React from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, Box } from "@mui/material";
import { useGetUploadsByTypeQuery, useDeleteUploadMutation } from '../../../redux/apiSlice';
import AddDoc from './AddDoc';
import ViewDoc from './ViewDoc';
import DeleteAlert from '../../../DeleteAlert';

const ExternalDocs = () => {
    const { clientId } = useParams()
    const type = 'external'

    const { data, isLoading, isSuccess } = useGetUploadsByTypeQuery({clientId, type})
    // console.log("data: ", data)

    const [deleteUpload] = useDeleteUploadMutation();

    const columns = [
        { field: "title", headerName: "Title", width: 150 },
        { 
            field: "uploadDate", 
            headerName: "Upload Date", 
            width: 150,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                let options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                };
                return date.toLocaleString("en-US", options);
              }
        },
        { 
            field: "expires", 
            headerName: "Expires on", 
            width: 150,
            valueFormatter: (params) => {
                // console.log("params: ", params.value)
                if(params.value === null) {
                    return ''
                } else {
                const date = new Date(params.value);
                let options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                };
                return date.toLocaleString("en-US", options);
            }
              }
        },
        {
            field: "actions",
            headerName: "",
            width: 80,
            renderCell: (params) => {
                // console.log(params.row.file)
                // console.log("base64: ", params.row.file.base64)
                let uploadId = params?.id;
                let docTitle = params?.row?.title;
                let docName = params?.row?.name
                return (
                    <>
                        <Box
                            sx={{
                            // backgroundColor: "whitesmoke",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                            }}
                        >
                        <ViewDoc doc={params?.row?.file} title={docTitle} name={docName} />
                        <DeleteAlert name={`Client Doc-${docTitle}`} id={uploadId} hook={deleteUpload} softDelete={false} />
                        </Box>
                    </>
                )
            }
        }

    ]

    let content

    if (isLoading) {
        content = <CircularProgress />;
    } else if (isSuccess) {
        content = (
            <div style={{ display: "flex" }}>
                
                <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
                    {data && (
                        <DataGrid
                            autoHeight
                            autoWidth
                            getRowId={(row) => row._id}
                            rows={data}
                            columns={columns}
                            sx={{ height: "100%", width: "100%" }}
                        />
                    )}
                </Box>
            </div>
        )
    }

  return (
    <div>
        <h1>External Agency Documents</h1>
        <AddDoc type={type} clientId={clientId} />
        <div>{content}</div>
    </div>
  )
}

export default ExternalDocs;