import React, {useState} from 'react';
import {Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEditSettingsMutation } from '../redux/apiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { changeAgencyName } from '../redux/slices/settingsSlice';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';


const EditSettings = ({data}) => {
    // console.log(data)
    const [open, setOpen] = useState(false);
    const [ddctToggle, setDdctToggle] = useState(false);
    const [fedRateToggle, setFedRateToggle] = useState(false);
    const [calendarYear, setCalendarYear] = useState('outlined');
    const [fiscalYear, setFiscalYear] = useState('outlined');

    const agencyId = useSelector(state => state.settings.id)
    const dispatch = useDispatch()

    const [editSettings] = useEditSettingsMutation()

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
      };

    //   console.log("data: ", data)

    const {register, handleSubmit, setValue} = useForm({
        defaultValues: {
            name: data?.name,
            regionalCenter: data?.regionalCenter,
            useStdDdct: data?.useStdDdct,
            useFedRate: data?.useFedRate,
            fedRate: data?.fedRate,
            yearType: data?.yearType
        }
    })

    const onSubmit = async (data) => {
        // console.log('data: ', data)

        try{
            dispatch(changeAgencyName(data.name))
            // const payload = 
            await editSettings({ agencyId, data}).unwrap();
            // console.log('fulfilled', payload)
        } catch (err) {
            console.error('Failed to update settings: ', err)
        }
        toast.success("Settings Updated", {
          position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
    }

    const onError = () => {
        alert("Please check the requirements for the form");
        console.log('error on update')
    }

    const handleCalendarVariant = () => {
        if (calendarYear === 'outlined') {
            setCalendarYear('contained')
            setFiscalYear('outlined')
            setValue('yearType', 'calendarYear')
            
        } else {
            setCalendarYear('outlined')
            setValue('yearType', 'fiscalYear')
        }
    }

    const handleFiscalVariant = () => {
        if (fiscalYear === 'outlined') {
            setFiscalYear('contained')
            setCalendarYear('outlined')
            setValue('yearType', 'fiscalYear')
            
        } else {
            setFiscalYear('outlined')
            setValue('yearType', 'calendarYear')
        }
    }

    // console.log("stdDdct: ", ddctToggle)
    
  return (
    <div>
        <Button color='primary' variant='contained' onClick={handleClickOpen}>Edit Settings</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Agency Settings</DialogTitle>
            <DialogContent>
                <form id="editSettings" 
                    onSubmit={(e) =>
                    handleSubmit(onSubmit, onError)(e).catch((e) => {
                        console.log("e", e);
                    })}>

                <label>Agency Name: {data?.name}</label><br />
                <label>Change Name:</label><br />
                <TextField {...register('name')} /> <br />
                <Typography>Set Default Regional Center?</Typography>
                <TextField {...register('regionalCenter')} />
                <Checkbox 
                    value={ddctToggle}
                    // onChange={handleStdDdct}
                    onClick={() => setDdctToggle(!ddctToggle)}
                    {...register('useStdDdct')}
                />
                 <label>Use Standard Deduction on Mileage?</label> <br />
                 <Checkbox
                    value={fedRateToggle}
                    onClick={() => setFedRateToggle(!fedRateToggle)}
                    {...register('useFedRate')}
                />
                <label>Use Federal Rate for Milage?</label> <br />
                 {fedRateToggle && 
                 <>
                 <Typography>Current Federal Rate: </Typography>
                 <TextField
                    type='number'
                    InputProps={{ inputProps: { step: 'any' } }}
                    {...register('fedRate')}
                /> 
                </>
                }
                <Typography>Year Type for Contracts Tracking:</Typography> <br />
                <Typography>Calendar Year = Jan 1 - Dec 31, Fiscal Year = Jul 1 - Jun 30</Typography>
                <span>
                    <Button color='secondary' variant={calendarYear} onClick={handleCalendarVariant}>Calendar Year</Button>
                    <Button color='secondary' variant={fiscalYear} onClick={handleFiscalVariant}>Fiscal Year</Button>
                </span>

                </form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' type='submit' form='editSettings'>Submit</Button>
                <Button color='warning' variant='contained' onClick={handleClose}>Cancel/ Close</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

EditSettings.propTypes = {
    data: PropTypes.any
}

export default EditSettings