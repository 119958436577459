import React, {useRef, useState} from 'react';
import { Dialog, DialogActions, DialogContent, Slide,
       DialogContentText, Button, TextField, Typography} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import SelectAsyncUser from '../../utilities/SelectAsyncUser';
import SelectAsyncClient from '../../utilities/SelectAsyncClient';
import { useAddNewVisitMutation } from '../../redux/apiSlice';
import { toast } from 'react-toastify';
import { useAuth } from '../../utilities/useAuth';
import { useGetUserName } from '../../utilities/useGetUserName';
import useFocusTrap from '../../utilities/useFocusTrap';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function ScheduleVisit() {
  const {id, role} = useAuth();
  const isUser = id;
  const userName = useGetUserName(isUser);

  const [addNewVisit] = useAddNewVisitMutation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialStart = new Date().toISOString()
  const initialEnd = new Date(new Date().getTime() + 3600000).toISOString()

  // console.log("initials: ", initialStart, initialEnd)

  const { control, register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
    client: '',
    user: isUser,
    location: '',
    visitStart: initialStart,
    visitEnd: initialEnd,
    status: 'Scheduled'
    }
  });

  // console.log("errors: ", errors)

   

  const onSubmit= async (data) => {
    console.log("data: ", data)
    try {
      // const payload =  
      await addNewVisit({ data, isUser })
      // .unwrap();
      //   console.log('fulfilled', payload);
        toast.success("Visit Scheduled", {
          position: toast.POSITION.TOP_RIGHT
        });
      } catch (err) {
        console.error('Failed to add visit: ', err);
        toast.error("Error Scheduling Visit", {
          position: toast.POSITION.TOP_CENTER
        });
      }
      
      
      handleClose();
            
   };

   const onError = () => {
    alert("Please check the requirements for the form");
    console.log('error');
  };

  const dialogRef = useRef(null);

  // console.log("open and dialogRef: ", open, dialogRef)

  useFocusTrap(open, dialogRef, handleClose);


  const handleDialogEntered = () => {
    if (dialogRef.current) {
      const focusableElements = dialogRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );

      if (focusableElements.length > 0) {
        const firstElement = focusableElements[0];
        firstElement.focus();
  
        // Scroll the focused element to the center top of the viewport
        firstElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Schedule Client Visit
      </Button>
      <Dialog 
        open={open} 
        onClose={handleClose}
        disableAutoFocus={true}
        TransitionComponent={Transition}
        TransitionProps={{ onEntered: handleDialogEntered }}
      >
        
        <DialogContent>
          <DialogContentText>
            Complete the following sections to schedule visit.
          </DialogContentText>
          <div ref={dialogRef}>
          
          <form id="scheduleVisit" 
              onSubmit={(e) =>
              handleSubmit(onSubmit, onError)(e).catch((e) => {
                console.log("e", e);
              })}>
          
          <section > 
          {/* ref={formRef} tabIndex={-1} */}
            {role === 'Staff' ? (
               <>
               <Typography variant="body1">{userName}</Typography>
               <input
                 type="hidden"
                 {...register('user', { value: isUser })}
               />
             </>
            ) : (
              <>
                <label>Select User</label>
                <Controller
                  control={control}
                  name='user'
                  errors={errors}
                  rules={{ required: true }}
                  render={({ field: {onChange, value} }) => (     
                    <SelectAsyncUser 
                      placeholder='Enter User Name' 
                      handleChange = {(_id  => onChange(_id))}
                      value={value}
                  />  
                  )}
              />   
              </>
            )}
            
           <br></br>
          {errors.user && "Please Select a User"}
          </section>

          <section>
            <label>Select Client</label>
          <Controller
            control={control}
            name="client"
            errors={errors}
            rules={{ required: true }}
            render={({ field: {onChange, value} }) => (     
              <SelectAsyncClient 
                placeholder='Enter Client Name' 
                handleChange = {(_id  => onChange(_id))}
                value={value}
          />  
      )}
          />   
     <br></br>
     {errors.client && "Please Select a Client"}
     </section>
     
     <br></br>
     <section>
       <Controller
            control={control}
            name="location"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
              autofocus
                label="Location"
                error={!!errors.location}
                //helperText={error ? error.message : null}
                fullWidth
                onChange={field.onChange}
                value={field.value}
                variant="outlined"
              />
      )}
     />
     <br></br>
     {errors.location && "Please State Location"}
     </section>
     <br></br>

     <section>
      
      <Controller
        control={control}
        name="visitStart"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
         
          label="Visit Start Date/ Time"
          error={!!errors.visitStart}
          value={new Date(field.value).setSeconds(0, 0)}
          onChange={(newValue) => {
            const normalizedValue = new Date(newValue);
            normalizedValue.setSeconds(0, 0);
            field.onChange(normalizedValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.visitStart && "Select Start Date/ Time"}
      </section>
      <br></br>
      <section>
      
      <Controller
        control={control}
        name="visitEnd"
        
  
      render={({ field }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
         
          label="Visit End Date/ Time"
          error={!!errors.visitEnd}
          value={new Date(field.value).setSeconds(0, 0)} 
          onChange={(newValue) => {
            const normalizedValue = new Date(newValue);
            normalizedValue.setSeconds(0, 0);
            field.onChange(normalizedValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
      )}
      />
      <br></br>
      {errors.visitEnd && "Select End Date/ Time"}
      </section>
      
        </form>
        </div>
        <br></br>        
                  
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='warning'>Cancel</Button>
          <Button form="scheduleVisit" type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
