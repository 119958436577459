import React, {useState} from 'react';
import { Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAddSettingsMutation } from '../redux/apiSlice';
import { toast } from 'react-toastify';

const AddSettings = () => {
    const [open, setOpen] = useState(false)
    const [ddctToggle, setDdctToggle] = useState(false);
    const [fedRateToggle, setFedRateToggle] = useState(false);

    const [addSettings] = useAddSettingsMutation()

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
      };

    const {register, handleSubmit} = useForm({
        defaultValues: {
            name: '',
            useStdDdct: false,
            useFedRate: false,
            fedRate: ''
        }
    })

    const onSubmit = async (data) => {
        // console.log('data: ', data)

        try{
            // const payload = 
            await addSettings(data).unwrap();
            // console.log('fulfilled', payload)
        } catch (err) {
            console.error('Failed to add settings: ', err)
        }
        toast.success("Settings Added", {
          position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
    }

    const onError = () => {
        alert("Please check the requirements for the form");
        console.log('error on add')
    }

    // console.log("stdDdct: ", ddctToggle)
    
  return (
    <div>
        <Button color='primary' variant='contained' onClick={handleClickOpen}>Add Settings</Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Agency Settings</DialogTitle>
            <DialogContent>
                <form id="addSettings" 
                    onSubmit={(e) =>
                    handleSubmit(onSubmit, onError)(e).catch((e) => {
                        console.log("e", e);
                    })}>

                <label>Set Agency Name:</label><br />
                <TextField {...register('name')} /> <br />
                <Checkbox 
                    value={ddctToggle}
                    // onChange={handleStdDdct}
                    onClick={() => setDdctToggle(!ddctToggle)}
                    {...register('useStdDdct')}
                />
                 <label>Use Standard Deduction on Mileage?</label> <br />
                <Checkbox
                    value={fedRateToggle}
                    onClick={() => setFedRateToggle(!fedRateToggle)}
                    {...register('useFedRate')}
                />
                <label>Use Federal Rate for Milage?</label> <br />
                 {fedRateToggle && 
                 <>
                 <Typography>Current Federal Rate: </Typography>
                 <TextField
                    type='number'
                    InputProps={{ inputProps: { step: 'any' } }}
                    {...register('fedRate')}
                /> 
                </>
                }

                </form>
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant='contained' type='submit' form='addSettings'>Submit</Button>
                <Button color='warning' variant='contained' onClick={handleClose}>Cancel/ Close</Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

export default AddSettings