import { createSlice } from '@reduxjs/toolkit'

export const visitSlice = createSlice({
    name: 'visit',
    initialState: {
        visit: {
            client: '',
            location: '',
            visitStart: '',
            visitEnd: '',
            goals: [],
            cancelNote: '',
            status: '',
            clientSig: {}
        },
        openModal: false,
        openScheduler: false,
        schedulerTime: ''
        // trigger: false
    },
    reducers: {
        resetVisit(state) {
            state.visit.client = '',
            state.visit.location = '',
            state.visit.visitStart = '',
            state.visit.visitEnd = '',
            // state.visit.goals = [],
            state.visit.status = '',
            state.visit.cancelNote = '',
            state.visit.clientSig = {}
        },
        setOpenState(state, action){
            state.openModal = action.payload
            // state.trigger = true
        },
        setScheduleOpenState(state, action) {
            state.openScheduler = action.payload.open
            state.schedulerTime = action.payload.time
        },
        // setScheduleTimeState(state, action){
        //     state.schedulerTime = action.payload
        // },
        setVisitDetails(state, action) {
            state.visit.client = action.payload.client
            // state.user = action.payload.user
            state.visit.location = action.payload.location
            state.visit.visitStart = action.payload.visitStart
            state.visit.visitEnd = action.payload.visitEnd
            state.visit.status = action.payload.status
            state.visit.cancelNote = action.payload.cancelNote
            state.visit.clientSig = action.payload.clientSig
        },
        setVisitGoals(state, action) {
            state.visit.goals = [...action.payload]
        },
        setMarkedGoals(state, action) {
            state.visit.goals = state.goals.push(action.payload)
        },
        // setVisitforSig(state, action) {
        //     state.visit = action.payload.data

        // }
        
    }
    
})

export const { resetVisit, resetOpenState, setOpenState, setVisitDetails, setVisitGoals, setMarkedGoals, setScheduleOpenState  } = visitSlice.actions

export default visitSlice.reducer

