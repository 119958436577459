import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SelectAsyncUser from '../../utilities/SelectAsyncUser';
import { setTimecardSearch, setGetAllTimecards } from '../../redux/slices/adminSlice';
import { useDispatch } from 'react-redux';


const TimecardSearch = () => {
    const dispatch = useDispatch();

    const [rangeSelect, setRangeSelect] = useState('outlined')
    const [submitSelect, setSubmitSelect] = useState('outlined')
    const [dates, setDates] = useState([new Date(new Date().setDate(new Date().getDate() - 7)), new Date()]);
    const [clearSearch, setClearSearch] = useState(false);

    const { setValue, control, handleSubmit, reset } = useForm({ 
        defaultValues: {
            dateRange: [dates],
            byRange: false,
            bySubmit: false,
            userId: []
        }
    });

    const handleRangeVariant = () => {
        if (rangeSelect === 'outlined') {
            setRangeSelect('contained')
            setSubmitSelect('outlined')
            setValue('byRange', true)
            setValue('bySumbit', false)
            
        } else {
            setRangeSelect('outlined')
            setValue('byRange', false)
        }
    }

    const handleSubmitVariant = () => {
        if (submitSelect === 'outlined') {
            setSubmitSelect('contained')
            setRangeSelect('outlined')
            setValue('bySubmit', true)
            setValue('byRange', false)
        } else {
            setSubmitSelect('outlined')
            setValue('bySubmit', false)
        }
    }

    

  
    const onSubmit= async (data) => {
        console.log("timecard search data: ", data)

        dispatch(setTimecardSearch(data));
        setClearSearch(true);
        reset();
        // setTimeout(() => setClearSearch(false), 300)
        setClearSearch(false);
    }
    

  return (
    <div>
        <Box>
        <form id="timecardSearch" 
              onSubmit={(e) =>
              handleSubmit(onSubmit)(e).catch((e) => {
                console.log("e", e);
              })}>
            <Button color='primary' variant='contained' onClick={() => dispatch(setGetAllTimecards())}>Pull UnApproved</Button>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <fieldset width='300px'>
                    <legend>To search by dates, select range and option for either submission date or pay period range</legend>
                <DateRangePicker
                    value={dates}
                    onChange={(newDates) => {
                        new Date().toISOString(newDates);
                        setValue('dateRange', newDates);
                        setDates(newDates)			
                    }}
                    renderInput={(startProps, endProps) => (
                        <React.Fragment>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 2}}> to </Box>
                            <TextField {...endProps} />
                        </React.Fragment>
                    )}
                />
                </fieldset>
            </LocalizationProvider>
            <br />
            <span>
                <Button color='primary' variant={submitSelect} onClick={handleSubmitVariant}>Submission Date</Button>
                <Button color='primary' variant={rangeSelect} onClick={handleRangeVariant}>Date Range</Button>
            </span>
            <span>
                <Typography>Select User</Typography>
                <Controller
                    control={control}
                    name="userId"
                    render={({ field }) => (
                
                        <SelectAsyncUser  
                        ref={field.ref}
                            placeholder='Enter User Name'
                            handleChange = {(_id  => field.onChange(_id))}
                            clearSearch={clearSearch}
                            onChange={(e)=>field.onChange(e)}
                            value={field.value}
                            theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'blue',
                                primary: 'black'
                            }
                        })}
                    />  
                )}
                />
            </span>
            <br />
            <Button color='primary' variant='contained' type='submit' form='timecardSearch'>Run</Button>
            </form>
        </Box>
    </div>
  )
}

export default TimecardSearch